import ReactDOM from 'react-dom/client';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './src/styles/global.scss';
// import VConsole from 'vconsole';

export function onClientEntry() {
  console.log('onClientEntry');

  // const vConsole = new VConsole();
  // console.log(vConsole);

  // (function() {
  //   if (typeof window.WeixinJSBridge == 'object' && typeof window.WeixinJSBridge.invoke == 'function') {
  //     handleFontSize();
  //   } else {
  //     if (document.addEventListener) {
  //       document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
  //     } else if (document.attachEvent) {
  //       document.attachEvent('WeixinJSBridgeReady', handleFontSize);
  //       document.attachEvent('onWeixinJSBridgeReady', handleFontSize);
  //     }
  //   }
  //
  //   function handleFontSize() {
  //     // 设置网页字体为默认大小
  //     window.WeixinJSBridge.invoke('setFontSizeCallback', {
  //       'fontSize': 0,
  //     });
  //     // 重写设置网页字体大小的事件
  //     window.WeixinJSBridge.on('menu:setfont', function() {
  //       window.WeixinJSBridge.invoke('setFontSizeCallback', {
  //         'fontSize': 0,
  //       });
  //     });
  //   }
  // })();

  getRem(375, 25);
  window.onresize = function() {
    getRem(375, 25);
  };

  function getRem(pwidth, prem) {
    var html = document.getElementsByTagName('html')[0];
    var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
    if (oWidth < 768) {
      html.style.fontSize = oWidth / pwidth * prem + 'px';
      // alert(html.style.fontSize);
    } else {
      html.style.fontSize = '50px';
      // alert('2:' + html.style.fontSize);
    }
  }

  window.onload = () => {
    (function(a, h, c, b, f, g) {
      a['UdeskApiObject'] = f;
      a[f] = a[f] || function() {
        (a[f].d = a[f].d || []).push(arguments);
      };
      g = h.createElement(c);
      g.async = 1;
      g.charset = 'utf-8';
      g.src = b;
      c = h.getElementsByTagName(c)[0];
      c.parentNode.insertBefore(g, c);
    })(window, document, 'script', 'https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js', 'ud');
    window.ud({
      'code': 'h8g20g5',
      'link': 'https://wujirtc.s4.udesk.cn/im_client/?web_plugin_id=16495',
      'isInvite': true,
      'mode': 'inner',
      'color': '#307AE8',
      'pos_flag': 'srb',
      'onlineText': '联系客服，在线咨询',
      'offlineText': '客服下班，请留言',
      'selector': '#kf-btn',
      'mobile': { //为响应式布局，提供pc、mobile自定制
        'mode': 'blank',
        'color': '#307AE8',
        'pos_flag': 'crb',
        'onlineText': '联系客服，在线咨询',
        'offlineText': '客服下班，请留言',
      },
    });
  };

}

export function onInitialClientRender() {
}

export function shouldUpdateScroll({
  routerProps: {location},
  getSavedScrollPosition,
}) {
  window.scrollTo(0, 0);
  const body = document.getElementsByTagName('body')[0];
  body.scrollTop = 0;
  return true;
}

// export const replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container);
//     root.render(element);
//   };
// };
