exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ecology-tsx": () => import("./../../../src/pages/ecology.tsx" /* webpackChunkName: "component---src-pages-ecology-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-acceleration-tsx": () => import("./../../../src/pages/product/acceleration.tsx" /* webpackChunkName: "component---src-pages-product-acceleration-tsx" */),
  "component---src-pages-product-recording-tsx": () => import("./../../../src/pages/product/recording.tsx" /* webpackChunkName: "component---src-pages-product-recording-tsx" */),
  "component---src-pages-product-rtm-tsx": () => import("./../../../src/pages/product/rtm.tsx" /* webpackChunkName: "component---src-pages-product-rtm-tsx" */),
  "component---src-pages-product-video-call-tsx": () => import("./../../../src/pages/product/video-call.tsx" /* webpackChunkName: "component---src-pages-product-video-call-tsx" */),
  "component---src-pages-product-video-live-tsx": () => import("./../../../src/pages/product/video-live.tsx" /* webpackChunkName: "component---src-pages-product-video-live-tsx" */),
  "component---src-pages-product-voice-call-tsx": () => import("./../../../src/pages/product/voice-call.tsx" /* webpackChunkName: "component---src-pages-product-voice-call-tsx" */),
  "component---src-pages-product-voice-live-tsx": () => import("./../../../src/pages/product/voice-live.tsx" /* webpackChunkName: "component---src-pages-product-voice-live-tsx" */),
  "component---src-pages-solution-conference-tsx": () => import("./../../../src/pages/solution/conference.tsx" /* webpackChunkName: "component---src-pages-solution-conference-tsx" */),
  "component---src-pages-solution-ecommerce-tsx": () => import("./../../../src/pages/solution/ecommerce.tsx" /* webpackChunkName: "component---src-pages-solution-ecommerce-tsx" */),
  "component---src-pages-solution-education-tsx": () => import("./../../../src/pages/solution/education.tsx" /* webpackChunkName: "component---src-pages-solution-education-tsx" */),
  "component---src-pages-solution-finance-tsx": () => import("./../../../src/pages/solution/finance.tsx" /* webpackChunkName: "component---src-pages-solution-finance-tsx" */),
  "component---src-pages-solution-game-tsx": () => import("./../../../src/pages/solution/game.tsx" /* webpackChunkName: "component---src-pages-solution-game-tsx" */),
  "component---src-pages-solution-health-tsx": () => import("./../../../src/pages/solution/health.tsx" /* webpackChunkName: "component---src-pages-solution-health-tsx" */),
  "component---src-pages-solution-social-tsx": () => import("./../../../src/pages/solution/social.tsx" /* webpackChunkName: "component---src-pages-solution-social-tsx" */)
}

